import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiPhoneCall, FiMail } from "react-icons/fi";
import {Link} from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="social-icon social-default color-lessdark justify-content-start">
                                <li><a href="tel:+33 6 70 80 37 65" target='_blank'><FiPhoneCall /></a></li>
                                <li><a href="mailto:contact@modai-consulting.com" target='_blank'><FiMail /></a></li>
                                <li><a href="https://www.linkedin.com/in/benjamin-tabet/" target='_blank'><FiLinkedin /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right">
                            <ul className="ft-menu link-hover align-right-new">
                                <li><a href="../privacy-policies">Privacy Policy</a></li>
                                <li><a href="#">Copyrights 2024</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightTwo;