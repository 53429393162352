import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/serviice-01.jpg',
        title: 'UI/UX Design',
        description: 'UI (User Interface) designers focus on clear navigation and content presentation. UX (User Experience) designers create emotional, user-friendly experiences.'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'Website Creation',
        description: 'Craft professional websites to showcase your business or portfolio.'
    },
    {
        image: '/images/service/serviice-03.jpg',
        title: 'Software Platform Creation',
        description: 'Build flexible and customizable software platforms. Whether it’s for e-commerce, mobile apps, or web services.'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Data Analysis Dashboard',
        description: 'Visualize key metrics with analytics dashboards. Monitor performance, collaborate, and make informed decisions across various domains.'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;