import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || Web Solution Consulting Company</title>
            <meta name="robots" content="index, follow" />
            <meta name="description" content="Discover our company specializing in custom SAAS platform creation. From requirements gathering to production deployment, we handle everything: design, front-end and back-end development, integration, and testing. Get a turnkey solution perfectly tailored to your needs" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta property="og:image:alt" content="Modai-Consulting" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="360" />
            <meta property='og:site_name' content="Modai-Consulting" />
            <meta property='og:url' content="modai-consulting.com/" />
            <meta property='og:title' content="Modai-Consulting" />
            <meta property='og:description' content="Discover our company specializing in custom SAAS platform creation. From requirements gathering to production deployment, we handle everything: design, front-end and back-end development, integration, and testing. Get a turnkey solution perfectly tailored to your needs" />
            <meta property='og:type' content="website" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


