import React from 'react';
import SEOModai from "../common/SEOModai";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderModai from '../common/header/HeaderModai';
import ModaiFooter from '../common/footer/ModaiFooter';
import ScrollTop from '../common/footer/ScrollTop';
import PortfolioModai from '../elements/portfolio/PortfolioModai';
import ServiceModai from "../elements/service/ServiceModai";
import TimelineModai from "../elements/timeline/TimelineModai";
import TestimonialModai from "../elements/testimonial/TestimonialModai";
import ServiceModaiTwo from "../elements/service/ServiceModaiTwo";
import BrandModaiTwo from '../elements/brand/BrandModaiTwo';
import ServiceModaiThree from "../elements/service/ServiceModaiThree";
import TimelineModaiAbout from "../elements/timeline/TimelineModaiAbout";
import BrandModai from '../elements/brand/BrandModai';
import ContactModai from "../elements/contact/ContactModai";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";


const CreativePortfolio = () => {
    return (
        <>
            <SEOModai title="Modai Consulting" />
            <main className="page-wrapper">
                <HeaderModai btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div id='home' className="slider-area slider-style-1 bg-transparent height-650">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">MODAI CONSULTING</span>
                                    <h1 className="title theme-gradient display-two">Concept To Reality</h1>
                                    <p className="description">At the intersection of passion and precision, we craft solutions that inspire, innovate, and elevate your success.</p>
                                    <a className="btn-default btn-medium round btn-icon" href="#how-we-work">How we work <i className="icon"><FiArrowRight /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Projects Area  */}
                <div id='projects' className="rwt-portfolio-area rn-section-gapTop">
                    <div className="container-fluid plr--60 plr_md--15 plr_sm--15">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Projects"
                                    title = "Check out our latest projects"
                                    description = "From concept to creation, our team is here to transform your vision into a stunning reality."
                                />
                            </div>
                        </div>
                        <PortfolioModai Column="col-lg-4 mt--30 box-grid-layout no-overlay" />
                    </div>
                </div>

                <Separator />

                {/* Start Service Area  */}
                <div id='services' className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Services"
                                        title = "Turning Your Vision into Reality"
                                        description = "From concept to creation, our team is here to transform your vision into a stunning reality."
                                     />
                                </div>
                                
                            </div>
                            <ServiceModai 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                            <div className="col-lg-12 pt--60 text-center">
                                <div class="read-morebtn">
                                    <a class="btn-default btn-border round" href="#contact"><span>Get in touch</span></a>
                                </div>
                            </div>
                        </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Timeline Area  */}
                <div id='how-we-work' className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "How We Work"
                                    title = "Step Order Process"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineModai />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                <Separator />

                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "Backed by a team driven by confidence, quality, and transparency, we ensure your project receives the meticulous attention and care it deserves, delivering exceptional results every step of the way."
                                />
                            </div>
                        </div>
                        <TestimonialModai teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "100% Guarantee"
                                    title = "Client Satisfaction Guarantee"
                                    description = ""
                                 />
                            </div>
                        </div>
                        <ServiceModaiTwo 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-start"
                         />
                        <div className="col-lg-12 pt--60 text-center">
                            <div class="read-morebtn">
                                <a class="btn-default btn-border round" href="#contact"><span>Get in touch</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Tech Logo Area  */}
                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tech we work with"
                                    title = "Design, Websites, Apps & Much More"
                                    description = "Backed by a team driven by confidence, quality, and transparency, we ensure your project receives the meticulous attention and care it deserves, delivering exceptional results every step of the way."
                                />
                            </div>
                            <div className="col-lg-12 mt--30 mb--30">
                                <BrandModaiTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tech Logo Area  */}


                {/* Start Service Area 
                <div id='about' className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What We Can Do For You"
                                        title = "The Team"
                                        description = "Backed by a team driven by confidence, quality, and transparency, we ensure your project receives the meticulous attention and care it deserves, delivering exceptional results every step of the way."
                                     />
                                </div>
                            </div>
                            <ServiceModaiThree 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                </div>
                End Service Area  */}

                <Separator />

                {/* Start About Area  */}
                <div className="rwt-timeline-area rn-section-gap pb--0">
                    <div className="container">
                        <div className="row">
                            <div class="col-lg-12 text-center pb--40">
                                <h4>Benjamin Tabet</h4>
                                <p>CEO and Founder, modAI Consulting</p>
                                <img class="usr-img" src="./images/ben.png"></img>
                            </div>
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "The Mastery Behind Our Work"
                                    description = "With our founder's diverse expertise, we ensure high-quality, sustainable solutions for clients. We collaborate closely with you, from startups to large corporations, to address any challenges and meet your needs efficiently."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineModaiAbout classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div> 
                {/* End About Area  */}              

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--30 mb--30">
                                <BrandModai brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <Separator />

                {/* Start Contact Area  */}
                <div id='contact' className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Contact Us"
                                    title = "Let's discuss about your project."
                                    description = ""
                                />
                            </div>
                        </div>
                        <ContactModai />
                    </div>
                </div>
                {/* End Contact Area  */}

                

                <ModaiFooter />
                <ScrollTop />
            </main>
        </>
    )
}
export default CreativePortfolio;