import React from 'react';
import ContactFormModai from "./ContactFormModai";
import { FiCalendar, FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="inner">
                                        <h4 className="title"><FiCalendar className="icon" /> Book an appointment</h4>
                                        <div class="read-morebtn">
                                            <a class="btn-default btn-border round" href="https://app.lemcal.com/@modai-consulting" target='_blank'>
                                                <span>Book an appointment</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="inner">
                                        <h4 className="title"><FiHeadphones className="icon" /> Call us</h4>
                                        <p><a href="tel:+33 6 70 80 37 65">+33 6 70 80 37 65</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="inner">
                                        <h4 className="title"><FiMail className="icon" /> Mail us</h4>
                                        <p><a href="mailto:contact@modai-consulting.com">contact@modai-consulting.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactFormModai formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <img src="./images/contact.png"></img>
                </div>
            </div>
        </>
    )
}
export default ContactOne;