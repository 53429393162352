import React from 'react';
import SEOModai from "../common/SEOModai";
import HeaderModai from '../common/header/HeaderModai';
import ModaiFooter from '../common/footer/ModaiFooter';
import ScrollTop from '../common/footer/ScrollTop';



const CreativePortfolio = () => {
    return (
        <>
            <SEOModai title="Modai Consulting" />
            <main className="page-wrapper">
                <HeaderModai btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                <div className="post-page-banner rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="content">
                                    <div className="page-title">
                                        <h1 className="theme-gradient">Privacy Policies</h1>
                                        <p className='desc'>
                                            Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site modai-consulting.com les présentes mentions légales.
                                            <br></br><br></br>
                                            La connexion et la navigation sur le site par l’Utilisateur impliquent acceptation intégrale et sans réserve des présentes mentions légales. Ces dernières sont accessibles sur le site à la rubrique « Terms & Condition ».
                                        </p>
                                        <h4>ARTICLE 1 : L’éditeur</h4>
                                        <p className='desc'>
                                            L’édition du site modai-consulting.com est assurée par la Société SAS MODAI CONSULTING au capital de 500,00 € euros, immatriculée au RCS de Paris sous le numéro 982 444 531 dont le siège social est situé à Courbevoie, adresse e-mail : contact@modai-consulting.com
                                            <br></br><br></br>
                                            N° de TVA intracommunautaire : FR51982444531
                                            <br></br><br></br>
                                            Le Directeur de la publication est Benjamin Tabet, CEO de Modai Consulting.
                                        </p>
                                        <h4>ARTICLE 2 : L’hébergeur</h4>
                                        <p className='desc'>Le site modai-consulting.com est hébergé sur OVH dans l'Union européenne (Paris, France).</p>
                                        <h4>ARTICLE 3 : L'accès au site</h4>
                                        <p className='desc'>
                                            Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découler d’une nécessité de maintenance.
                                            <br></br><br></br>
                                            En cas de modification, interruption ou suspension des services le site modai-consulting.com ne saurait être tenu responsable.
                                        </p>
                                        <h4>ARTICLE 4 : La collecte de données</h4>
                                        <p className='desc'>
                                            L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
                                            <br></br><br></br>
                                            En naviguant sur le site, il choisit de les accepter ou non. Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet.
                                            <br></br><br></br>
                                            L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            

                <ModaiFooter />
                <ScrollTop />
            </main>
        </>
    )
}
export default CreativePortfolio;