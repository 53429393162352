import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="../#home">Home</a></li>
            <li><a href="../#projects">Projects</a></li>
            <li><a href="../#services">Services</a></li>
            <li><a href="../#how-we-work">How we work</a></li>
            <li><a href="../#about">About</a></li>
            <li><a href="../#contact">Contact</a></li>
        </ul>
    )
}
export default Nav;
