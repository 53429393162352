import React from 'react';
import HeaderModai from '../common/header/HeaderModai';
import ModaiFooter from '../common/footer/ModaiFooter';


const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderModai btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <ModaiFooter />
            </main>
        </>
    )
}
export default Layout;
