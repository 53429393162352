import React from 'react';
import { FiActivity, FiArrowUp, FiCast, FiClock, FiMap, FiStar, FiWind } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: "01",
        title: 'Client-Centric Approach',
        description: 'Your satisfaction is our priority. We tailor our support services to meet your unique needs and ensure a smooth post-delivery experience.'
    },
    {
        image: "02",
        title: 'Rapid Response Time',
        description: 'Count on us for swift responses to your queries and concerns. Our team is dedicated to resolving issues promptly, minimizing downtime.'
    },
    {
        image: "03",
        title: 'Long-Term Partnership',
        description: 'Building long-term relationships with our clients is integral to our ethos. We are committed to supporting your online journey every step of the way.'
    },
    {
        image: "04",
        title: 'Peace of Mind',
        description: 'With our comprehensive post-delivery support, you can focus on your core business activities with the peace of mind that your website is in expert hands.'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="thumbnail">
                                <img className="service-icon" src={`./images/service/${val.image}.png`} alt="Corporate React Template" />
                            </div>
                            <div className="content">
                                <h4 className="title w-600" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;