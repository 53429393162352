import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Idea Presentation",
        description: "",
        image: "timeline-01",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Understand client's need, vision, budget & deadline.",
            },
            {
                stepTitle: "",
                stepDescription: "• Visualize concept with graphics.",
            },
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "Clarification of requirements",
        description: "",
        image: "timeline-02",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Gather initial requirements from client.",
            },
            {
                stepTitle: "",
                stepDescription: "• VIdentify any ambiguities or gaps in information.",
            },
            {
                stepTitle: "",
                stepDescription: "• Seek clarification through questions and discussions.",
            },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Implementation and validation of flow charts",
        description: "",
        image: "timeline-03",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Develop initial flowchart based on client requirements.",
            },
            {
                stepTitle: "",
                stepDescription: "• Present flowchart to client for review and feedback.",
            },
            {
                stepTitle: "",
                stepDescription: "• Incorporate client's feedback and make necessary revisions.",
            },
            {
                stepTitle: "",
                stepDescription: "• Validate final flowchart with client to ensure accuracy and alignment with objectives.",
            },
            
        ]
    },
    {
        id: "4",
        date: "Step-4",
        title: "Design launch and validation by iteration",
        description: "",
        image: "timeline-04",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Design initial product or service based on Flow charts.",
            },
            {
                stepTitle: "",
                stepDescription: "• Gather feedback from client and end-users.",
            },
            {
                stepTitle: "",
                stepDescription: "• Iterate on design based on feedback, repeating the validation process until satisfaction is achieved.",
            },
        ]
    },
    {
        id: "5",
        date: "Step-5",
        title: "Development launch and validation by iteration",
        description: "",
        image: "timeline-05",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Develop the product or service according to client specifications and requirements.",
            },
            {
                stepTitle: "",
                stepDescription: "• Launch the initial version or prototype for testing and evaluation.",
            },
            {
                stepTitle: "",
                stepDescription: "• Gather feedback from clients, users, and stakeholders.",
            },
            {
                stepTitle: "",
                stepDescription: "• Iterate on the development based on feedback, repeating the validation process until the desired outcomes are achieved.",
            },
        ]
    },
    {
        id: "6",
        date: "Step-6",
        title: "Final Delivery and Launch",
        description: "",
        image: "timeline-06",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Prepare the final version of the product or service based on client feedback and requirements.",
            },
            {
                stepTitle: "",
                stepDescription: "• Conduct thorough testing to ensure quality and functionality.",
            },
            {
                stepTitle: "",
                stepDescription: "• Coordinate with the client to schedule the official launch date.",
            },
            {
                stepTitle: "",
                stepDescription: "• Deliver the finalized product or service to the client and launch it to the target audience or market.",
            },
        ]
    },
    {
        id: "7",
        date: "Step-7",
        title: "Post-Delivery Support",
        description: "",
        image: "timeline-07",
        workingStep: [
            {
                stepTitle: "",
                stepDescription: "• Continuous Assistance: Our commitment doesn't end with the website delivery. We provide ongoing support to ensure your online presence evolves seamlessly.",
            },
            {
                stepTitle: "",
                stepDescription: "• Updates and Maintenance: We handle all updates and maintenance tasks, guaranteeing your website's optimal performance and security.",
            },
            {
                stepTitle: "",
                stepDescription: "• Training and Guidance: Empowering you to manage your website efficiently, we offer personalized training sessions and comprehensive guidance.",
            },
            {
                stepTitle: "",
                stepDescription: "• Growth Strategies: Beyond the launch, we devise growth strategies to enhance your website's effectiveness and adapt to changing market trends.",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-8 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30 pb--40">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-12 col-md-12 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p className='projectpoints'>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-border round" href="https://app.lemcal.com/@modai-consulting" target='_blank'><span>Book an appointment</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-4">
                                    <div className="thumbnail">
                                        <img className="w-80" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
