import React from 'react';

const BrandList = [
    {
        image: '/images/service/tech1.png',
    },
    {
        image: '/images/service/tech2.png',
    },
    {
        image: '/images/service/tech3.png',
    },
    {
        image: '/images/service/tech4.png',
    },
    {
        image: '/images/service/tech5.png',
    },
    {
        image: '/images/service/tech6.png',
    }
]

const BrandTwo = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img className="w-80" src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandTwo;
